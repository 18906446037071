import "../Pages.scss";
import heroImg from "../../Assets/Hero/VA-hero.jpeg";
import aboutImg from "../../Assets/About/VA-about.png";
import map from "../../Assets/Map/VA-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  t80Images,
  t101Images,
  t108Images,
} from "../../Assets/Product/Vanya Avenue/VAImages";
const imageMap = {
  t80Images,
  t101Images,
  t108Images,
};
const Vanya = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"VANYA AVENUE"}
        imgAlt={"Vanya Avenue Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Vanya Avenue About Image"}
        aboutTitle={"VANYA AVENUE"}
        aboutDescription={
          "Vanya Avenue sebagai pilihan optimal dengan pasar potensial yang besar. Berlokasi strategis, langsung bersebelahan dengan area komersial Vana Lakeside dan danau Vanya Park. Toko-toko di Vanya Avenue merupakan produk terbaru dari Vanya Park BSD, dengan hanya 12 unit tersedia untuk dijual. Dapatkan potensi pasar yang besar dari ribuan penghuni di sekitar dan kembangkan bisnis Anda di lingkungan yang mendukung pertumbuhan."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Vanya%20Avenue)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />

      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.Vanyacards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Alesha 3 menit.",
          "Assana, Assati 2 menit.",
          "AEON Mall 11 menit.",
          "ICE BSD, QBIG 8 menit.",
          "Wisma BCA 9 menit.",
          "Green Office Park 14 menit.",
          "Atmajaya 14 menit.",
          "Exit Tol Serpong-Balaraja 15 menit.",
          "Intermoda, Tol AEON 10 menit.",
          "Eka Hospital, RS Columbia Asia BSD 15 menit.",
          "IPEKA 5 menit.",
        ]}
      />
    </div>
  );
};

export default Vanya;
