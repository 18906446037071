import "../Pages.scss";
import heroImg from "../../Assets/Hero/NU-hero.jpeg";
import aboutImg from "../../Assets/About/NU-about.jpeg";
import flyerImg from "../../Assets/Flyer/NU-flyer.webp";
import map from "../../Assets/Map/NU-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  standardImages,
  standard2Images,
  cornerImages,
  corner2Images,
  balconyImages,
} from "../../Assets/Product/Northridge Ultimate/NUImages";
const imageMap = {
  standardImages,
  standard2Images,
  cornerImages,
  corner2Images,
  balconyImages,
};
const NU = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"NORTHRIDGE ULTIMATE"}
        imgAlt={"Northridge Ultimate Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Northridge Ultimate About Image"}
        aboutTitle={"NORTHRIDGE ULTIMATE"}
        aboutDescription={
          "Northridge Business Center di BSD CITY adalah pusat bisnis generasi berikutnya yang terletak strategis di kawasan North BSD, menawarkan peluang bisnis yang luar biasa. Dikelilingi oleh kluster premium di jantung distrik komersial, pusat ini memiliki akses mudah dan jaringan jalan yang luas, menjadikannya ideal untuk berbagai skala usaha."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Northridge%20Ultimate)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN NORTHRIDGE ULTIMATE"}
        pointData={{
          poin1: [
            "Subsidi DP",
            "Insentif Buka Usaha 50Jt",
            "Multiple Signage Spot",
            "Spacious Balcony",
          ],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruan%20Northridge%20Ultimate)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.NUcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Northridge Ultimate Location Image"}
        pointData={[
          "QBIG 1 menit.",
          "LULU 1 menit.",
          "Menara BCA 1 menit.",
          "Showroom BYD 1 menit.",
          "ICE 4 menit.",
          "Aeon Mall dan Akses Tol 5 menit.",
        ]}
      />
    </div>
  );
};

export default NU;
