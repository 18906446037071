import "../Pages.scss";
import heroImg from "../../Assets/Hero/Delrey-hero.webp";
import aboutImg from "../../Assets/About/Delrey-about.webp";
import flyerImg from "../../Assets/Flyer/Delrey-flyer.webp";
import map from "../../Assets/Map/Delrey-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import PromoTenants from "../../Section/Promo/PromoTenants";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  delrey6Images,
  delrey7Images,
  delrey8Images,
} from "../../Assets/Product/Delrey/DelreyImages";
import dr1 from "../../Assets/Product/Delrey/dr1.png";
import dr2 from "../../Assets/Product/Delrey/dr2.png";
import dr3 from "../../Assets/Product/Delrey/dr3.png";
import dr4 from "../../Assets/Product/Delrey/dr4.png";
const imageMap = {
  delrey6Images,
  delrey7Images,
  delrey8Images,
};
const promoImages = [dr1, dr2, dr3, dr4];
const Delrey = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"DELREY"}
        imgAlt={"Delrey Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Delrey About Image"}
        aboutTitle={"DELREY"}
        aboutDescription={
          "Sinarmas Land BSD City kembali menghadirkan kawasan bisnis terbaru nya, DELREY BUSINESS TOWNHOUSE (Delrey Biztown). Satu konsep yang sudah lama menjadi impian para pebisnis yaitu pemilik bisnis mendapatkan kesempatan maksimal untuk mengembangkan usaha F&B/ Retail dan menjalankan pekerjaan profesional lainnya, bahkan memiliki hidup yang berkualitas sekaligus dalam 1 tempat."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(DELREY)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <PromoTenants
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN DELREY"}
        pointData={{
          poin1: ["Subsidi DP", "Voucher Informa 20Jt", "Cashback Up to 80Jt"],
          poin2: [
            "Unit Terbatas",
            "Hadap Jalan Boulevard",
            "Memiliki banyak tenant",
          ],
        }}
        promoImages={promoImages}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20DELREY)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.Delreycards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Delrey Location Image"}
        pointData={[
          "Tol Serpong–Balaraja, Stasiun Cisauk 10 menit.",
          "Tol Jakarta–Serpong 23 menit.",
          "AEON Mall 7 menit.",
          "Grand Lucky Superstore 3 menit.",
          "Foresta Business Loft, Astra Biz Centre 5 menit.",
          "ICE Business Park 6 menit.",
          "Universitas Prasetya Mulya 6 menit.",
          "IPEKA BSD City 3 menit.",
          "Jakarta Nanyang School 5 menit.",
          "RS Columbia Asia, RS Medika 11 menit.",
        ]}
      />
    </div>
  );
};

export default Delrey;
