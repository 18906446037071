import "../Pages.scss";
import heroImg from "../../Assets/Hero/loop-hero.webp";
import aboutImg from "../../Assets/About/loop-about.webp";
import flyerImg from "../../Assets/Flyer/loop-flyer.webp";
import map from "../../Assets/Map/loop-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  standardImages,
  balconyImages,
  hoekImages,
} from "../../Assets/Product/The loop/LoopImages";
const imageMap = {
  standardImages,
  balconyImages,
  hoekImages,
};
const Loop = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"THE LOOP"}
        imgAlt={"The Loop Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"The Loop About Image"}
        aboutTitle={"THE LOOP"}
        aboutDescription={
          "Shophouse dengan Konsep F&B di Pusat Komunitas Olahraga Terbesar. Terletak di Central BSD, dikelilingi oleh berbagai pasar potensial seperti kantor-kantor, mahasiswa, dan pelajar di kawasan Mozia Loop, Prasetya Mulia, ICE, Ipeka, Nanyang School, serta hunian kelas menengah atas seperti Zora, Quantis, Greenwich Park, Enchante, Nava Park, dan sekitarnya. Konsep bisnis komersial ini memiliki tiga lantai."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(The%20Loop)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN THE LOOP"}
        pointData={{
          poin1: [
            "Subsidi DP",
            "Additional Discount 2%",
            "Siap Huni",
            "Free PPN 100% (220Jt)",
          ],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20The%20Loop)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.Loopcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Quantis sport club (Kyzn) 2 menit.",
          "Sekolah Ipeka BSD 2 menit.",
          "Qbig, Mitra 10, Gedung wisma BCA, perkantoran Foresta Business Loft 3 menit.",
          "Perumahan The Zora, Mozia, Eminent 3 menit.",
          "Akses Tol, Aeon Mall, Kampus Prasmul, ICE 5 menit.",
        ]}
      />
    </div>
  );
};

export default Loop;
