import "../Pages.scss";
import heroImg from "../../Assets/Hero/WVP-hero.jpeg";
import aboutImg from "../../Assets/About/WVP-about.jpeg";
import flyerImg from "../../Assets/Flyer/WVP-flyer.webp";
import map from "../../Assets/Map/WVP-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  stdImages,
  balconyImages,
  facadesImages,
  slImages,
} from "../../Assets/Product/West Village Park/WVPImages";
const imageMap = {
  stdImages,
  balconyImages,
  facadesImages,
  slImages,
};
const WVP = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"WEST VILLAGE PARK"}
        imgAlt={"West Village Park Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"West Village Park About Image"}
        aboutTitle={"WEST VILLAGE PARK"}
        aboutDescription={
          "West Village Business Park di BSD City memiliki desain fasad yang modern dan nyaman, dengan ruang fleksibel untuk berbagai jenis bisnis, termasuk kantor, kafe, dan ritel. Lokasinya strategis dekat dengan fasilitas seperti AEON Mall dan The Breeze, serta pengembangan yang akan datang seperti RANS Nusantara Hebat dan EASTVARA Mall yang dijadwalkan dibuka pada tahun 2024. Taman ini juga mencakup bangunan fasad ganda yang meningkatkan aksesibilitas dan potensi bisnis bagi para pengusaha."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(West%20Village%20Park)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN WEST VILLAGE PARK"}
        pointData={{
          poin1: ["Subsidi DP", "Discount 11%", "Cashback 50jt"],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20West%20Village%20Park)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.WVPcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"West Village Location Image"}
        pointData={[
          "Grand Lucky, The Barn dan Rans Nusantara Hebat 3 menit.",
          "Mall Eastvara, Sekolah Ipeka, Q big 5 menit.",
          "Akses ke Gading Serpong 5 menit.",
          "BSD City Park (Jatim park, Cimory Dairyland, Heha) 10 menit.",
          "Akses Tol, Aeon Mall, ICE, Prasmul 12 menit.",
        ]}
      />
    </div>
  );
};

export default WVP;
