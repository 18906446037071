import "../Pages.scss";
import heroImg from "../../Assets/Hero/CC-hero.webp";
import aboutImg from "../../Assets/About/CC-about.webp";
import flyerImg from "../../Assets/Flyer/CC-flyer.webp";
import map from "../../Assets/Map/CC-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  premiumBImages,
  premiumImages,
} from "../../Assets/Product/Capital Cove/CCImages";
const imageMap = {
  premiumBImages,
  premiumImages,
};
const CC = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"CAPITAL COVE"}
        imgAlt={"Capital Cove Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Capital Cove About Image"}
        aboutTitle={"CAPITAL COVE"}
        aboutDescription={
          "Capital Cove adalah ruang bisnis inovatif yang dirancang dengan konsep elegan di BSD City. Lokasi ini menawarkan nilai tambah untuk bisnis Anda, dikelilingi oleh lingkungan hijau dan kawasan residensial elit. Desain fasad yang mewah menggunakan material kaca lebar dan pencahayaan yang baik akan meningkatkan nilai serta prestise bisnis Anda."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Capital%20Cove)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN CAPITAL COVE"}
        pointData={{
          poin1: ["Discount 2,4M", "Voucher elektronik 50Jt", "Cashback 200Jt"],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Capital%20Cove)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.CCcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Intermoda BSD City 2 menit.",
          "Cisauk Train Station, Tol Road BSD – Jakarta Selatan 5 menit.",
          "Future Tol Road BSD – Balaraja 6 menit.",
          "Pelabuhan Tanjung Priok 50 menit.",
        ]}
      />
    </div>
  );
};

export default CC;
