import plaza from "./plaza.webp";
import promenade from "./promenade.webp";

const promenadeImages = [promenade, promenade];
const promenadeHookImages = [promenade, promenade];
const promenadeBackyardImages = [promenade, promenade];
const plazaImages = [plaza, plaza];

export {
  promenadeImages,
  promenadeHookImages,
  promenadeBackyardImages,
  plazaImages,
};
