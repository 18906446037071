import "./Newlaunch.scss";
import newlaunch from "../../Assets/Newlaunch/Newlaunch Desk.webp";
import newlaunch2 from "../../Assets/Newlaunch/Newlaunch Mob.webp";

const Newlaunch = () => {
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(New%20Launch%20Ruko%20Asterra)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  return (
    <section className="section-newlaunch" id="newlaunch">
      <div className="container">
        <h4 className="title">
          New Launching <br />
          Ruko Asterra Business Park
        </h4>
        <img src={newlaunch} alt="newlaunch" className="img-desktop" />
        <img src={newlaunch2} alt="newlaunch" className="img-mobile" />
        <button onClick={waOpen} className="btn-about">
          Learn More
        </button>
      </div>
    </section>
  );
};

export default Newlaunch;
