import "./Hero-pages.scss";

const HeroPages = ({ img, imgAlt, pageTitle }) => {
  return (
    <section className="hero-pages">
      <img src={img} alt={imgAlt} className="hero-pages-img" />
      <div className="container">
        <div className="box">
          <h6 className="caption">Welcome to</h6>
          <h3 className="title">{pageTitle}</h3>
        </div>
      </div>
    </section>
  );
};

export default HeroPages;
