import React from "react";
import "./About.scss";
import { ReactComponent as Love } from "../../Assets/svg/love.svg";
import about1 from "../../Assets/About/ah.webp";
import about2 from "../../Assets/About/ah2.webp";
import about4 from "../../Assets/About/ah4.webp";
import about5 from "../../Assets/About/ah5.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {
  const imagemap = [about1, about2, about4, about5];
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="aboutcont" id="about">
      <div className="center">
        <div className="about">
          <div className="caption">BSD CITY</div>
          <div className="title">BIG CITY BIG OPPORTUNITY</div>
          <div className="des">
            BSD City merupakan kota mandiri yang dikembangkan oleh Sinar Mas
            Land dan menjadi pusat hunian, edukasi, hiburan, dan inovasi digital
            di mana semua fasilitas disediakan, mulai dari kawasan industri,
            perkantoran, perdagangan, pendidikan, wisata, hingga perumahan.
            Sinar Mas Group mempopulerkan nama "BSD City," serta menambahkan
            slogan "Big City, Big Opportunity".
          </div>
          <div className="item">
            <div className="poin">
              <ul>
                <li>
                  <Love />
                  &nbsp;More Than 200.000+ Residents
                </li>
                <li>
                  <Love /> &nbsp;Easy Access
                </li>
                <li>
                  <Love />
                  &nbsp;Private Parking
                </li>
              </ul>
            </div>
            <div className="poin">
              <ul>
                <li>
                  <Love />
                  &nbsp;More Than 500.000 Daily Activity
                </li>
                <li>
                  <Love />
                  &nbsp;Near 8 Luxury Residentials
                </li>
                <li>
                  <Love />
                  &nbsp;Strategic Location
                </li>
              </ul>
            </div>
          </div>
          <div className="button">
            <button
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0",
                  "_blank"
                )
              }
              className="wabutton"
            >
              Lihat Selengkapnya
            </button>
          </div>
        </div>
        <div className="aboutimg">
          <Slider {...settings}>
            {imagemap.map((card, index) => (
              <img
                alt={`gallery ${index + 1}`}
                src={card}
                key={index}
                className="sliderimg"
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default About;
