import "./Promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const PromoTenants = ({
  flyer,
  promoTitle,
  pointData,
  waLink,
  promoImages,
}) => {
  const waOpen = () => {
    window.open(`${waLink}`, "_blank");
  };
  return (
    <section id="promo" className="section-promo">
      <div className="container">
        <div className="flyer">
          <img src={flyer} alt="promo flyer" />
        </div>
        <div className="highlights">
          <h4>{promoTitle}</h4>
          <div className="highlights-bottom">
            <div className="msg-hook">
              <div className="container-point">
                {pointData.poin1.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="container-point">
                {pointData.poin2.map((item, index) => (
                  <div className="point-item" key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} className="icon" />
                    <span>{item}</span>
                  </div>
                ))}
              </div>

              <div className="tenant">
                {promoImages.map((image, index) => (
                  <img
                    src={image}
                    alt="gambar list tenant"
                    className="tenant-img"
                    key={index}
                  />
                ))}
              </div>

              <div className="container-btn">
                <div className="term">*T&C apply</div>
                <button onClick={waOpen} className="btn">
                  <FontAwesomeIcon icon={faWhatsapp} className="icon" />
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromoTenants;
